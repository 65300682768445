import type { InitOptions, i18n } from 'i18next';

export const i18n_NS = {
  DEFAULT: 'common',
} as const;

export const i18nextOptions: InitOptions & { fallbackLng: string; supportedLngs: string[] } = {
  fallbackLng: 'pl',
  supportedLngs: ['pl'],
  defaultNS: i18n_NS.DEFAULT,
  react: { useSuspense: false },
};

export const intlDateTimeRange = (i18nInstance: i18n) =>
  i18nInstance.services.formatter?.addCached('datetimerange', (lng, options) => {
    const dateTimeRange = new Intl.DateTimeFormat(lng, { ...options });
    return (val) => dateTimeRange.formatRange(val[0], val[1]);
  });
